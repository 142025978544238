import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TermsComponent } from '@pages/terms/terms.component';

@Component({
  selector: 'cam-footer',
  standalone: true,
  imports: [RouterLink, TermsComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  constructor(private router: Router) { }
}
