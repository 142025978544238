import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SessionStorageKeys } from '@interfaces/enum/SessionStorageKeys.enum';
import { CultureCode } from '@type/CultureCode.type';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private readonly _http = inject(HttpClient);

  public culture: CultureCode = 'it-IT'; /* FIXME: Dev purposes */
  // public user: UserProfileInterface;
  public isAdmin = true; /* FIXME: Dev purposes */
  public isLogged = true; /* FIXME: Dev purposes */

  public get csrfToken() {
    return sessionStorage.getItem(SessionStorageKeys.XSRF_TOKEN);
  }
}
