import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import Swal from '@global/config/defaultSwal';
import { HttpResponseStatus } from '@interfaces/enum/HttpResponseStatus.enum';

export const HttpResponseInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case HttpResponseStatus.UNAUTHORIZED:
          Swal.fire({
            icon: 'error',
            title: 'Unauthorized',
            text: 'Non sei autorizzato ad accedere a questa risorsa. Reindirizzamento al login...',
          }).then(() => {
            // Redirect to login
            window.location.href = '/login';
          });
          break;
        case HttpResponseStatus.FORBIDDEN:
          Swal.fire({
            icon: 'error',
            title: 'Forbidden',
            text: 'Non hai i permessi per accedere a questa risorsa. Reindirizzamento al login...',
          }).then(() => {
            // Redirect to login
            window.location.href = '/login';
          });
          break;
        case HttpResponseStatus.PRECONDITION_FAILED:
          Swal.fire({
            icon: 'error',
            title: 'Precondition Failed',
            text: 'Il server non è stato in grado di soddisfare una delle condizioni della richiesta.',
          });
          break;
        case HttpResponseStatus.PAYLOAD_TOO_LARGE:
          Swal.fire({
            icon: 'error',
            title: 'Payload Too Large',
            text: 'Il payload della richiesta è troppo grande.',
          });
          break;
        case HttpResponseStatus.INTERNAL_SERVER_ERROR:
          Swal.fire({
            icon: 'error',
            title: 'Internal Server Error',
            text: 'Si è verificato un errore interno del server. Riprova più tardi.',
          });
          break;
        default:
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Si è verificato un errore. Riprova più tardi.',
          });
      }

      // Rethrow the error so that the service and component can catch it
      return throwError(() => error);
    }),
  );
};
