import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import {AuthService} from "@services/auth.service";

export const LoggedOutGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.logout().then((logout) => {
    if(logout) {
      authService.clearLoggedUser();
      return true;
    } else {
      return router.createUrlTree(['/403']);
    }
  }).catch((err) => {
    console.error('Error during guard execution:', err);  // Debugging output
    return router.createUrlTree(['/403']);
  });
};
