<div class="container-fluid bg-secondary">
  <div class="row d-flex justify-content-between">
    <!-- Mobile Layout -->
    <ng-container *ngIf="isMobile; else desktopLayout">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <div>
          <img
            src="../../../../assets/Negativo.png"
            alt="logo costa"
            class="logo-scaled"
            loading="eager"
          />
        </div>

        <div class="d-flex align-items-center gap-3">
          <div ngbDropdown class="d-inline-block">
            <button
              class="d-flex justify-content-center align-items-center border border-white rounded-circle text-white"
              style="
                width: 36px;
                height: 36px;
                background-color: transparent;
                border: none;
              "
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              {{ initials }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <a
                href="/logout"
                class="dropdown-item d-flex justify-content-between align-items-center gap-5 p-2 px-3"
              >
                <p class="m-0">Effettua il logout</p>
                <i class="bi bi-box-arrow-right"></i>
              </a>
            </div>
          </div>

          <button class="navbar-toggler" type="button" (click)="toggleMenu()">
            <i class="bi bi-list h1 text-white"></i>
          </button>
        </div>
      </div>
      @if (menuOpen) {
        <div class="col-12 d-flex gap-3 p-3 mb-3">
          <a
            [routerLink]="['/catalog']"
            routerLinkActive="router-link-active"
            class="text-white"
            >Prodotti</a
          >
          <a
            [routerLink]="['/orders']"
            routerLinkActive="router-link-active"
            class="text-white"
            >Ordini</a
          >
        </div>
      }
    </ng-container>

    <!-- Desktop Layout -->
    <ng-template #desktopLayout>
      <div class="col-12 col-md-6 d-flex align-items-center gap-4 p-3">
        <img
          src="../../../../assets/Negativo.png"
          alt="logo costa"
          class="logo-scaled"
        />
        <a
          [routerLink]="['/catalog']"
          routerLinkActive="router-link-active"
          class="text-white"
          >Prodotti</a
        >
        <a
          [routerLink]="['/orders']"
          routerLinkActive="router-link-active"
          class="text-white"
          >Ordini</a
        >
      </div>
      <div class="col-12 col-md-6 d-flex align-items-center justify-content-end gap-3">
        <a href="https://www.costaextra.it"
           class="text-white router-link-active"
           style="margin-right: 20%;"><img src="https://www.costaextra.it/Style%20Library/CostaClickNew/images/extra-logo.svg"
           style="width: 100px;"></a>

      </div>

    </ng-template>
  </div>
</div>
