<div class="app-container d-flex flex-column min-vh-100">
  <cam-navbar></cam-navbar>

  <main class="main-content">
    <router-outlet></router-outlet>
  </main>

  <cam-footer></cam-footer>
  <cam-cookie-banner></cam-cookie-banner>
</div>
