import { Routes } from '@angular/router';
import { IsAdminGuard } from '@guards/IsAdmin.guard';
import { IsLoggedGuard } from '@guards/IsLogged.guard';
import { LoggedOutGuard } from '@guards/LoggedOut.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'catalog',
    pathMatch: 'full',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'privacy',
    loadComponent: () =>
      import('./pages/privacy/privacy.component').then(
        (c) => c.PrivacyComponent,
      ),
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'cookies',
    loadComponent: () =>
      import('./pages/cookies/cookies.component').then(
        (c) => c.CookiesComponent,
      ),
    canActivateChild: [IsLoggedGuard],
  },
  // {
  //   path: 'terms',
  //   loadComponent: () =>
  //     import('./pages/terms/terms.component').then((c) => c.TermsComponent),
  //   canActivateChild: [IsLoggedGuard],
  // },
  {
    path: 'catalog',
    loadComponent: () =>
      import('./pages/catalog/catalog.component').then(
        (c) => c.CatalogComponent,
      ),
    // canActivateChild: [IsLoggedGuard],
    canActivate: [IsLoggedGuard],

  },
  {
    path: 'cart',
    loadComponent: () =>
      import('./pages/cart/cart.component').then(
        (c) => c.CartComponent,
      ),
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'info',
    loadComponent: () =>
      import('./pages/info/info.component').then(
        (c) => c.InfoComponent,
      ),
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'payment',
    loadComponent: () =>
      import('./pages/payment/payment.component').then(
        (c) => c.PaymentComponent,
      ),
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'summary',
    loadComponent: () =>
      import('./pages/summary/summary.component').then(
        (c) => c.SummaryComponent,
      ),
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'orders',
    loadComponent: () =>
      import('./pages/orders/orders.component').then((c) => c.OrdersComponent),
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'product',
    loadComponent: () =>
      import('./pages/product/product.component').then(
        (c) => c.ProductComponent,
      ),
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'stats',
    loadComponent: () =>
      import('./pages/stats/stats.component').then((c) => c.UnauthorizedComponent),
    canActivateChild: [IsAdminGuard],
    canActivate: [IsAdminGuard],
  },
  {
    path: '403',
    loadComponent: () =>
      import('./pages/stats/stats.component').then((c) => c.UnauthorizedComponent),
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./pages/stats/stats.component').then((c) => c.UnauthorizedComponent),
    canActivate: [LoggedOutGuard],
  },
];
