import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CWSResp } from '@interfaces/CWSResp.interface';
import { LoggedUserDTO } from '@interfaces/DTO/LoggedUserDTO';
import { catchError, lastValueFrom, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private csrfToken: string = '';
  isAdmin = false;
  private readonly _http = inject(HttpClient);
  private loggedUser!: LoggedUserDTO;


  public getLoggedUser(): LoggedUserDTO {
    return this.loggedUser;
  }

  public clearLoggedUser(): void {
    this.loggedUser = {} as LoggedUserDTO;
  }

  public isLoggedIn(): Promise<boolean> {
    const obs$ = this._http
      .get(`${environment.cws}/area-merchandising/GetLoggedUser`, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        tap((res) => {
          this.loggedUser = (res?.body as CWSResp<LoggedUserDTO>)?.result;
        }),
        map((res) => {
          return res.status === 200;
        }),
        catchError((err) => {
          console.error('Error during login check:', err);  // Debugging output
          return of(false);
        })
      );

    return lastValueFrom(obs$);
  }

  public logout(): Promise<boolean> {
    const obs$ = this._http
      .get(`${environment.cws}/area-merchandising/logout`, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res.status === 200;
        }),
        catchError((err) => {
          console.error('Error during login check:', err);  // Debugging output
          return of(false);
        })
      );

    return lastValueFrom(obs$);
  }
}
