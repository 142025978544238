import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "@services/config.service";

export const IsAdminGuard = () => {
  const isAdmin = inject(ConfigService).isAdmin;
  const _router = inject(Router);

  return isAdmin || _router.createUrlTree(['/homepage']);
}
