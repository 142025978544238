import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    RouterOutlet,
    NgClass,
  ],
})
export class AppComponent implements OnInit {
  private readonly _destroyRef = inject(DestroyRef)
  private readonly _router = inject(Router);

  public loaded = false;


  async ngOnInit() {
    this._router.events.pipe(filter((event) => event instanceof NavigationEnd), takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.debug('AppComponent', 'NavigarionEnded', `NavigationEnd.url = ${event.url}`);
      }
    });

    this.loaded = true;
  }

}
