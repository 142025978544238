import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cam-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class CookieBannerComponent implements OnInit {
  showBanner = false;
  private readonly CONSENT_KEY = 'cookieConsent';

  ngOnInit() {
    this.checkCookieConsent();
  }

  private checkCookieConsent(): void {
    const consent = localStorage.getItem(this.CONSENT_KEY);

    if (consent) {
      // Consent exists, check if it's expired
      const consentData = JSON.parse(consent);
      const expirationTime = consentData.expirationTime;

      if (new Date().getTime() < expirationTime) {
        // Consent is still valid
        this.showBanner = false;
        return;
      }
    }

    // either no consent or expired consent
    this.showBanner = true;
  }

  acceptCookies(): void {
    // Set expiration to 48 hours from now
    const expirationTime = new Date().getTime() + (48 * 60 * 60 * 1000);

    localStorage.setItem(this.CONSENT_KEY, JSON.stringify({
      consented: true,
      expirationTime: expirationTime
    }));

    this.showBanner = false;
  }
}
