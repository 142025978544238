import { CommonModule } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LoggedUserDTO } from '@interfaces/DTO/LoggedUserDTO';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { CartButtonComponent } from '../cart-button/cart-button.component';

@Component({
  selector: 'cam-navbar',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgbDropdownModule, CommonModule, CartButtonComponent],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  private loggedUser: LoggedUserDTO;
  public username: string;
  public initials: string;
  public windowWidth: number = window.innerWidth;
  public isMobile: boolean = this.windowWidth < 768;
  public menuOpen: boolean = false;

  readonly _authService = inject(AuthService);

  constructor() {
    this.loggedUser = this._authService.getLoggedUser();
    this.username = this.loggedUser?.FirstName + ' ' + this.loggedUser?.LastName;
    this.initials = this.loggedUser?.FirstName.charAt(0) + this.loggedUser?.LastName.charAt(0);  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.isMobile = this.windowWidth < 768;
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

}
