import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import {AuthService} from "@services/auth.service";

// export const IsLoggedGuard = () => {
//   const isLogged = inject(AuthService).isLoggedIn();
//   const _router = inject(Router);

//   return isLogged || _router.createUrlTree(['/403']);
// }

export const IsLoggedGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn().then((isLoggedIn) => {
    return isLoggedIn ? true : router.createUrlTree(['/403']);
  }).catch((err) => {
    console.error('Error during guard execution:', err);  // Debugging output
    return router.createUrlTree(['/403']);
  });
};
