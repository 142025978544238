<div class="container-fluid bg-secondary pt-3 px-4 pb-3">
  <div class="row pb-3">
    <div
      class="col-12 d-flex justify-content-between align-items-center pb-3 border-bottom"
    >
    <div>
      <img
        src="../../../../assets/Negativo.png"
        alt="logo costa"
        class="logo-scaled"
        loading="eager"
      />
    </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex gap-3">
      <p class="m-0 text-white">
        Costa Crociere S.p.A. | Partita IVA IT 02545900108 | Tutti i diritti
        riservati
      </p>
      <div class="vr bg-white opacity-100"></div>
      <a class="text-white" [routerLink]="['/privacy']" routerLinkActive="router-link-active" >Privacy Policy</a>
      <div class="vr bg-white opacity-100"></div>
      <a class="text-white" [routerLink]="['/cookies']" routerLinkActive="router-link-active" >Cookie Policy</a>
      <div class="vr bg-white opacity-100"></div>
      <a class="text-white" [routerLink]="['/terms']" routerLinkActive="router-link-active" >Termini & Condizioni di Vendita</a>
    </div>
  </div>
</div>
